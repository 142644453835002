import React from "react"
import { Container } from "react-bootstrap"

import classNames from "classnames"
import BackgroundImage from "gatsby-background-image"

import { formatHref } from "utils/helpers"
import CTAArrow from "assets/svg/cta-arrow"

import Image from "components/Image"

import styles from "./HomeCard.module.scss"

const HomeCard = (props) => {
  const Content = ({ header, topic, image, cta, alt }) => {

    let hashURL = formatHref(cta.url);

    if (hashURL.indexOf('signup') !== -1 ) {
      hashURL += '#getintouch';
    }

    return (
      <Container className={styles.container}>
        {image && (
          <div className={styles.imageContainer}>
            <Image className={styles.figure} node={{ ...image }} alt={alt} />
          </div>
        )}
        <div className={styles.caption}>
          <h2 className={styles.header}>{header.replace(/-/g, "\u2011")}</h2>
          <p className={styles.topic}>{topic}</p>
          <a className={styles.cta} href={hashURL}>
            {cta.label}
            <CTAArrow classes={styles.icon} />
          </a>
        </div>
      </Container>
    )
  }

  const ContentReverse = ({ header, topic, image, cta, alt }) => {

    let hashURL = formatHref(cta.url);

    if (hashURL.indexOf('signup') !== -1 ) {
      hashURL += '#getintouch';
    }

    return (
      <Container className={styles.container}>
        <div className={styles.captionReverse}>
          <h2 className={styles.header}>{header.replace(/-/g, "\u2011")}</h2>
          <p className={styles.topic}>{topic}</p>
          <a className={styles.cta} href={hashURL}>
            {cta.label}
            <CTAArrow classes={styles.icon} />
          </a>
        </div>
        {image && (
          <div className={styles.imageContainer}>
            <Image className={styles.figure} node={{ ...image }} alt={alt} />
          </div>
        )}
      </Container>
    )
  }

  const FinalContent = ({ header, topic, image, cta, alt }) => {
    return (
      <Container className={styles.container}>
        <div className={styles.contentContainer}>
          <h2 className={styles.header}>{header}</h2>
          <div className={styles.wrapper}>
            <div className={styles.bodyContainer}>
              {image && <Image className={styles.figure} node={{ ...image }} alt={alt}/>}
              <p className={styles.topic}>{topic}</p>
            </div>
            <a className={styles.cta} href={formatHref(cta.url)}>
              {cta.label}
              <CTAArrow classes={styles.icon} />
            </a>
          </div>
        </div>
      </Container>
    )
  }

  return (
    <Container
      className={classNames([styles.homeCard, styles[`card${props.index}`]], {
        [styles.even]: props.index % 2 === 0,
        [styles.odd]: props.index % 2 === 1,
      })}
      fluid
      as={"section"}
      style={{ paddingLeft: 0, paddingRight: 0 }}
    >
      {props.backgroundImage?.childImageSharp?.fluid ? (
        <BackgroundImage
          Tag="div"
          className={styles.backgroundImage}
          fluid={props.backgroundImage?.childImageSharp?.fluid}
          alt="Three children playing on a blanket"
        >
          {props.index === 3 ? (
            <FinalContent {...props} />
          ) : 
                        <Content {...props} />}
        </BackgroundImage>

      ) : (
                  <ContentReverse {...props} />
      )}
    </Container>
  )
}

export default HomeCard
