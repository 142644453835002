import React from "react"

import Image from "components/Image"

import styles from "./Hero.module.scss"

const BackgroundMask = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1440 260"
    className={styles.backgroundMask}
  >
    <path d="M1440-390.6h-0.5c0.2,0.1,0.4,0.2,0.5,0.3V-390.6z" />
    <path d="M1438,1.7C1149.5,246.8,379.2,324.9,0,199.9v59.6h1440V0C1439.3,0.6,1438.7,1.1,1438,1.7z" />
  </svg>
)

const BackgroundImage = ({ backgroundImage, mobileBackgroundImage, isOverview }) => {

  return (
    <div className={styles.backgroundImageContainer}>
      <Image
        className={styles.mobileBackgroundImage}
        node={mobileBackgroundImage}
        alt={"Three children playing together"}
      />
      <Image className={styles.backgroundImage} node={backgroundImage} alt={"Three children playing together"} />
      {!isOverview && 
        <BackgroundMask />
      }
    </div>
  )
}

export default BackgroundImage
