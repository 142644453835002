import React from "react"
import { Container } from "react-bootstrap"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

import { formatHref } from "utils/helpers"
import CTAArrow from "assets/svg/cta-arrow"

import BackgroundImage from "./BackgroundImage"
import Evergreen from "./Evergreen"
import Header from "./Header"

import styles from "./Hero.module.scss"

const Hero = ({
  backgroundImage,
  mobileBackgroundImage,
  caption,
  calloutImage,
  header,
  subHeader,
  topic,
  cta,
}) => {
  return (
    <Container fluid className={styles.hero}>
      <Evergreen caption={caption} calloutImage={calloutImage} />
      <Container>
        <div className={styles.headerContainer}>
          <Header {...header} />
          <a
            className={styles.cta}
            href={formatHref(cta.action.url)}
            target={cta.action.useNewWindow && "_blank"}
          >
            {cta.label}
            <CTAArrow classes={styles.icon} />
          </a>
        </div>
      </Container>
      <BackgroundImage
        backgroundImage={backgroundImage}
        mobileBackgroundImage={mobileBackgroundImage}
      />
        <div className={styles.mobileContent}>
          <a
            className={styles.cta}
            href={formatHref(cta.action.url)}
            target={cta.action.useNewWindow && "_blank"}
          >
            {cta.label}
            <CTAArrow classes={styles.icon} />
          </a>
        </div>
    </Container>
  )
}

export default Hero
