import React, { useState, useEffect } from "react"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

import styles from "./Hero.module.scss"

const Header = ({ content, references }) => {
  return (
    <>
      {documentToReactComponents(content, {
        renderMark: {
          [MARKS.BOLD]: (text) => (
            <strong className={styles.headerBold}>{text}</strong>
          ),
        },
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => {
            return <h1 className={styles.headerText}>{children}</h1>
          },
        },
      })}
    </>
  )
}

export default Header
