import { HCP_HERO, HCP_HOME_CARD } from "constants/ContentfulContentTypes"

export const useHomeData = ({ content, referencesHeader }) => {
  const hero = content.find((item) => item.__typename === HCP_HERO)
  const references = []

  references.push(
    ...referencesHeader.references
      .map((reference) => reference.title)
  )

  hero.caption?.references &&
    references.push(
      ...hero.caption?.references
        .filter((reference) => references.indexOf(reference) === -1)
        .map((reference) => reference.title)
    )

  hero.header?.references &&
    references.push(
      ...hero.header?.references
        .filter((reference) => references.indexOf(reference) === -1)
        .map((reference) => reference.title)
    )

  const heroCaption = {
    content: hero.caption?.richContent?.json,
    references:
      hero.caption?.references &&
      hero.caption?.references.map(
        (reference) => references.indexOf(reference.title) + 1
      ),
  }

  const heroHeader = {
    content: hero.header?.richContent?.json,
    references:
      hero.header?.references &&
      hero.header?.references.map(
        (reference) => references.indexOf(reference.title) + 1
      ),
  }

  const heroSubHeader = {
    content: hero.subHeader?.richContent?.json,
    references:
      hero.subHeader?.references &&
      hero.subHeader?.references.map(
        (reference) => references.indexOf(reference.title) + 1
      ),
  }

  const heroCTA = {
    action: {
      url: hero.cta?.action.url,
    },
    label: hero.cta?.label,
  }

  const heroTopic = {
    header: hero.topic?.title,
    content: hero.topic?.richContent?.json,
  }

  const heroBackgroundImage = hero.backgroundImage.localFile
  const heroMobileBackgroundImage = hero.mobileBackgroundImage.localFile
  const heroCalloutImage = hero.calloutImage.localFile

  const cards = content
    .filter((item) => item.__typename === HCP_HOME_CARD)
    .map((item) => {
      return {
        header: item.topic.title,
        topic: item.topic.content,
        cta: {
          label: item.cta.label,
          url: item.cta.action.url,
        },
        backgroundImage: item.backgroundImage?.localFile,
        image: item.image?.localFile,
        alt: item.alt
      }
    })

  return {
    hero: {
      caption: { ...heroCaption },
      header: { ...heroHeader },
      subHeader: { ...heroSubHeader },
      cta: { ...heroCTA },
      topic: { ...heroTopic },
      backgroundImage: heroBackgroundImage,
      mobileBackgroundImage: heroMobileBackgroundImage,
      calloutImage: heroCalloutImage,
    },
    cards,
    referencesHeader: referencesHeader.title,
    references,
  }
}
