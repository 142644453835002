import React from "react"

import { graphql } from "gatsby"

import { StateProvider } from "state/state"
import { reducer, initialState } from "state/reducer"
import { useHomeData } from "hooks/contentful/pages/useHomeData"

import Layout from "components/Layout"
import SEO from "components/Seo"
import Hero from "components/Hero/Hero"
import HomeCard from "components/HomeCard/HomeCard"

import "../styles/main.scss"
import styles from "../styles/pages/index.module.scss"

const IndexPage = ({ data }) => {
  const { hero, cards, references, referencesHeader } = useHomeData(data.page)
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Layout referencesHeader={referencesHeader} references={references}>
        <SEO title={data.page.title} description={data.page.description} />
        <main className={styles.content}>
          <Hero {...hero} />
          {cards.map((card, index) => {
            return <HomeCard index={index} key={index} {...card} />
          })}
        </main>
      </Layout>
    </StateProvider>
  )
}

export default IndexPage

export const query = graphql`
  query {
    page: contentfulHcpPage(label: { eq: "Home" }) {
      label
      url
      title
      description
      content {
        ...HeroFragment
        ...HomeCardFragment
      }
      referencesHeader {
        ...ReferencedTitleFragment
      }
    }
  }
`
