import React from "react"
import { Container } from "react-bootstrap"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { HERO_CAPTION_RICH_TEXT_OPTIONS } from "utils/contentfulRenderer"

import Image from "components/Image"

import styles from "./Hero.module.scss"

const Evergreen = ({ caption, calloutImage, isOverview }) => {
  let content = isOverview ? (
    <Container className={styles.evergreen}>
      <div className={styles.caption}>
        <h5>{caption}</h5>
      </div>
    </Container>
  ) : (
    <Container className={styles.evergreen}>
      <div className={styles.caption}>
        {documentToReactComponents(
          caption.content,
          HERO_CAPTION_RICH_TEXT_OPTIONS
        )}
      </div>
      <Image
        className={styles.callout}
        node={calloutImage}
        alt="The first and only FDA-approved treatment for peanut allergy"
      />
    </Container>
  )

  return <div className={styles.evergreenContainer}>{content}</div>
}

export default Evergreen
